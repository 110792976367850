<template>
  <div id="aboutView" class="viewComp">
    <div class="main">
      <h1>СОВладали с&nbsp;рекламной магией в&nbsp;2014&nbsp;году</h1>
      <div class="statsOL">
        <div class="stats">
          <div>
            <strong>300+</strong>
            <p>рекламных <br />кампаний</p>
          </div>
          <div>
            <strong>150+</strong>
            <p>
              клиентов<br />
              в портфолио <br />сотрудников
            </p>
          </div>
          <div>
            <strong>1 920 000+</strong>
            <p>часовой опыт в маркетинге <br />рекламе и PR проектах</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>
